<template>
  <div class="examine_container">
    <el-row class="examine_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <div class="examine_main">
      <div class="info">
        <div class="cardno">
          就诊号：
          <span>{{ patCardNo }}</span>
        </div>
        <div class="cardno">
          就诊人：
          <span>{{ patName }}</span>
        </div>
        <!-- <div class="block">
          <span class="demonstration">选择日期：</span>
          <el-date-picker
            v-model="value"
            type="date"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="selectTime"
            :editable="editable"
            :clearable="clearable"
          ></el-date-picker>
        </div> -->
      </div>
      <el-table :data="tableData" border="" style="width: 100%" ref="multipleTable" @row-click="on_select" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column label="开单日期" prop="time">
          <template slot-scope="scope">
            <span>{{ scope.row.time }}</span>
          </template>
        </el-table-column>
        <el-table-column label="检验编号" prop="examineNo">
          <template slot-scope="scope">
            <span>{{ scope.row.examineNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="检验科室" prop="dept">
          <template slot-scope="scope">
            <span>{{ scope.row.dept }}</span>
          </template>
        </el-table-column>
        <el-table-column label="检验名称" prop="examineName">
          <template slot-scope="scope">
            <span>{{ scope.row.examineName }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="btn">
        <el-button type="primary" @click.native="examineAll">{{ msg }}</el-button>
        <el-button type="danger" @click="print()">打印</el-button>
      </div>
    </div>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <el-button type="primary" @click.native="back" class="exBack">返回</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      editable: false,
      clearable: false,

      tableData: [
        {
          time: '2021-02-03',
          examineNo: '17056',
          dept: '妇科门诊',
          examineName: 'US'
        },
        {
          time: '2021-02-03',
          examineNo: '17056',
          dept: '妇科门诊',
          examineName: 'US'
        },
        {
          time: '2021-02-03',
          examineNo: '17056',
          dept: '妇科门诊',
          examineName: 'US'
        }
      ],
      multipleSelection: [],
      value: '',
      msg: '全选',
      patCardNo: '',
      patName: ''
    }
  },
  created() {
    this.patCardNo = this.$route.query.patCardNo
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.value = this.formatTime(0)
    this.checkResultList()
    this.getExamineReportDetail()
  },
  methods: {
    checkResultList() {
      this.loading = true
      let _this = this
      const params = {
        patCardType: '1',
        patIdCard: '440722197511071143',
        patCardNo: '',
        beginDate: '2022-09-01',
        endDate: '2022-12-22'
      }
      Promise.all([_this.$api.examineReportList(params)]).then(data => {
        // this.loading = false
        // this.tableData = data[0]
        // this.user = this.tableData[0].name
        // // 默认选中未打印
        // this.$nextTick(function () {
        //   data[0].forEach(row => {
        //     if (row.pacsPrt == 0) {
        //       this.$refs.multipleTable.toggleRowSelection(row);
        //     }
        //   });
        // });
      })
    },
    getExamineReportDetail() {
      this.loading = true
      let _this = this
      const params = {
        examNo: 'F3000A2022113035',
        patIdCard: '440722197511071143',
        patCardNo: '',
        patCardType: '1'
      }
      Promise.all([_this.$api.examineReportDetail(params)]).then(data => {
        // this.loading = false
        // this.tableData = data[0]
        // this.user = this.tableData[0].name
        // // 默认选中未打印
        // this.$nextTick(function () {
        //   data[0].forEach(row => {
        //     if (row.pacsPrt == 0) {
        //       this.$refs.multipleTable.toggleRowSelection(row);
        //     }
        //   });
        // });
      })
    },
    on_select(val) {
      //点击行选中复选框
      this.$refs.multipleTable.toggleRowSelection(val)
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    },
    selectTime(e) {
      console.log(e)
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear()
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      return [y, m, d].map(this.padStartConvert).join('-')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (val.length === this.tableData.length) {
        this.msg = '全不选'
      } else if (val.length === 0) {
        this.msg = '全选'
      }
    },
    examineAll() {
      this.$refs.multipleTable.toggleAllSelection()
      if (this.$refs.multipleTable.selection.length === 0) {
        this.msg = '全不选'
      } else if (this.$refs.multipleTable.selection.length === this.tableData.length) {
        this.msg = '全选'
      }
    },
    print() {
      if (this.$refs.multipleTable.selection.length === 0) {
        alert('请选择打印项目')
      } else {
        console.log(this.$refs.multipleTable.selection)
      }
    }
  }
}
</script>
<style>
.examine_container {
  width: 100%;
  height: 100%;
}

.examine_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.examine_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.examine_container .el-input__inner {
  border: 2px solid #000 !important;
  font-size: 24px !important;
}

.el-picker-panel__shortcut {
  line-height: 60px !important;
  font-size: 30px !important;
  text-align: center !important;
  padding: 0 !important;
}

.el-date-picker__header-label {
  font-size: 30px !important;
  line-height: 36px !important;
}

.el-picker-panel__icon-btn {
  font-size: 30px !important;
}

.el-date-table {
  font-size: 30px !important;
}

.el-date-table td {
  width: 50px !important;
  height: 50px !important;
  font-size: 20px !important;
}

.el-date-table td div {
  height: 50px !important;
}

.el-date-table td span {
  width: 46px !important;
  height: 46px !important;
  line-height: 46px !important;
}

.el-picker-panel {
  position: fixed !important;
  left: 670px !important;
}

.el-date-picker.has-sidebar {
  width: 560px !important;
}

.el-date-picker .el-picker-panel__content {
  width: 95% !important;
}

.el-checkbox__inner {
  border: 2px solid gray !important;
}

.el-month-table,
.el-year-table {
  font-size: 20px !important;
}

.examine_main {
  position: relative;
  margin: 0 auto;
  margin-top: 45%;
  width: 90%;
  height: 50%;
}

.examine_main .info {
  text-align: center;
  font-size: 24px;
  color: #409eff;
}

.examine_main .info .cardno,
.examine_main .info .block {
  display: inline-block;
  padding: 20px 160px;
}

.examine_main .el-table {
  margin-top: 20px;
}

.examine_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.examine_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.examine_container .el-checkbox__inner {
  width: 30px !important;
  height: 30px !important;
  margin-top: -7px !important;
  margin-left: 20px !important;
}

.el-checkbox__inner::after {
  left: 7px !important;
  top: 4px !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 8px !important;
}

.examine_main .btn {
  margin-top: 20px;
  text-align: center;
}

.examine_main .btn .el-button {
  font-size: 30px !important;
  margin: 0 50px !important;
}

.exBack {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
